import { getFunctions, httpsCallable, connectFunctionsEmulator } from "firebase/functions";

var stream: MediaStream, recorder: MediaRecorder
var chunks: Blob[] = []

function waitChunck() {
    return new Promise<void>(resolve => {
        const checkChunks = () => {
            console.log("checkChunks")
            if (chunks.length > 0) {
                resolve()
            } else {
                setTimeout(checkChunks, 1000)
            }
        }
        checkChunks()
    })
}


export async function Stop(): Promise<string> {
    recorder.stop()
    stream.getTracks().forEach((track) => {
        if (track.readyState === 'live') {
            track.stop();
        }
    });
    await waitChunck()
    console.log(chunks)

    let data = await chunks[0].arrayBuffer()
    let dataArray = Array.from(new Uint8Array(data))
    // Uncaught (in promise) RangeError: Maximum call stack size exceeded
    const base64String = btoa(
        String.fromCharCode.apply(null, dataArray)
    );
    const functions = getFunctions(undefined, "southamerica-east1")
    const isLocalENV = process.env.NODE_ENV
    console.log("isLocalENV", isLocalENV)
    if (isLocalENV == "development") {
        connectFunctionsEmulator(functions, "127.0.0.1", 5001)
    }

    console.log(base64String)
    const helloWorld = httpsCallable(functions, 'helloWorld');
    const result = await helloWorld({ file_base64: base64String })
    console.log(result.data);
    const url = URL.createObjectURL(chunks[0])
    return url
}

export async function Start() {
    chunks = []
    stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    recorder = new MediaRecorder(stream);
    recorder.ondataavailable = e => {
        chunks.push(e.data);
    }
    recorder.start();
}