import { db } from "../config/firebaseConfig";
import { collection, query, orderBy, getDocs } from "firebase/firestore";

export class Transcription {
    text!: string;
    created_at!: string;
}


export async function GetAllTranscriptions(userUID: string): Promise<Array<Transcription>> {
    const ref = collection(db, `transcriptions_by_user/${userUID}/transcriptions`)
    const q = query(ref, orderBy("created_at"));
    const querySnapshot = await getDocs(q);
    const results: Array<Transcription> = [];
    querySnapshot.forEach((doc) => {
        const data = doc.data()
        console.log(doc.id, " => ", data);
        results.push({
            text: data.text,
            created_at: data.created_at,
        })
    });
    console.log("results", results)
    return results
}