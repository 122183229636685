import React, { useEffect, useState } from 'react';
import microphoneSVG from '../../static/microphone.svg';
import microphoneActiveSVG from '../../static/microphone-active.svg';
import './App.css';
import { Start, Stop } from '../../controller/audio';
import { auth } from '../../config/firebaseConfig';
import { GoogleAuthProvider, onAuthStateChanged, signInWithPopup, signOut, User, createUserWithEmailAndPassword, signInWithEmailAndPassword } from 'firebase/auth';
import { GetAllTranscriptions, Transcription } from '../../repository/transcription';

function App() {

  const [loggedUser, setUser] = useState<User | null>(null)
  const [src, setSrc] = useState<string>()
  const [listening, setListening] = useState<boolean | null>(null)
  const [transcriptions, setTranscriptions] = useState<Array<Transcription> | null>(null)

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      console.log("setUser", user)
      if (user) {
        setUser(user)
        GetAllTranscriptions(user.uid).then(d => setTranscriptions(d))
      } else {
        setUser(null)
        setTranscriptions(null)
      }
    });
  }, [])



  const googleSignIn = () => {
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider)
  }
  const SignOut = () => {
    signOut(auth)
  }

  useEffect(() => {
    if (listening === null) {
      return
    }
    if (!listening) {
      Stop().then(url => {
        setSrc(url)
        if (loggedUser != null) {
          GetAllTranscriptions(loggedUser.uid).then(d => setTranscriptions(d))
        }
      }
      )
    } else {
      Start()
    }
  }, [listening])

  const imgSrc = listening ? microphoneActiveSVG : microphoneSVG

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [errorMsg, setErrorMsg] = useState('')

  const Create = () => {
    createUserWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed in 
        const user = userCredential.user;
        console.log(user)
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        setErrorMsg(errorMessage)
        console.log(error)
      });
  }
  const Login = () => {
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed in 
        const user = userCredential.user;
        console.log(user)
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        setErrorMsg(errorMessage)
        console.log(error)
      });
  }

  if (window.innerWidth > 600) {
    return (
      <div className="App">
        <header className="App-header">
          <h1>Hello {loggedUser?.email}</h1>
          <h2>Error {errorMsg}</h2>
          <h1>listening: {listening ? "true" : "false"}</h1>
          <img src={imgSrc} id="mic" alt="microphone icon" onClick={() => setListening(!listening)} />
          <form>
            <input value={email}
              placeholder="Enter your email here"
              onChange={(ev) => setEmail(ev.target.value)} />
            <input
              value={password}
              placeholder="Enter your password here"
              onChange={(ev) => setPassword(ev.target.value)}
            />
          </form>
          <button onClick={googleSignIn}>Google V2</button>
          <button onClick={Create}>Create</button>
          <button onClick={Login}>Login</button>
          <button onClick={SignOut}>SignOut</button>
          <audio controls={true} src={src} autoPlay={false} />
          <h1>{loggedUser?.displayName}</h1>
          <ul>
            {transcriptions?.map((t, i) => {
              return (
                <li key={'li-' + i}>{t.text} |---| {t.created_at}</li>
              )
            })}
          </ul>
        </header>
      </div>
    );
  }

  return (
    <div className="App">
      <header className="App-header">
        <img style={{width:'100%'}} src={imgSrc} id="mic" alt="microphone icon" onClick={() => setListening(!listening)} />
      </header>
    </div>
  );
}

export default App;
