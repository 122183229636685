import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCkKMHK-OZsrHHw7YmCn5H87WjsLWODVTg",
  authDomain: "hello-world-400b2.firebaseapp.com",
  projectId: "hello-world-400b2",
  storageBucket: "hello-world-400b2.appspot.com",
  messagingSenderId: "201800963241",
  appId: "1:201800963241:web:09ef823ac14624a40485b8"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

export { auth, db }


